fakeTag {
  @apply bg-red-500 bg-orange-500 bg-amber-500 bg-yellow-500 bg-lime-500 bg-green-500 bg-teal-500 bg-cyan-500 bg-sky-500 bg-blue-500 bg-indigo-500 bg-violet-500 bg-purple-500 bg-fuchsia-500 bg-pink-500 bg-rose-500 hidden
  text-red-500 text-orange-500 text-amber-500 text-yellow-500 text-lime-500 text-green-500 text-teal-500 text-cyan-500 text-sky-500 text-blue-500 text-indigo-500 text-violet-500 text-purple-500 text-fuchsia-500 text-pink-500 text-rose-500
  stroke-red-500 stroke-orange-500 stroke-amber-500 stroke-yellow-500 stroke-lime-500 stroke-green-500 stroke-teal-500 stroke-cyan-500 stroke-sky-500 stroke-blue-500 stroke-indigo-500 stroke-violet-500 stroke-purple-500 stroke-fuchsia-500 stroke-pink-500 stroke-rose-500
  hover:bg-red-500 hover:bg-orange-500 hover:bg-amber-500 hover:bg-yellow-500 hover:bg-lime-500 hover:bg-green-500 hover:bg-teal-500 hover:bg-cyan-500 hover:bg-sky-500 hover:bg-blue-500 hover:bg-indigo-500 hover:bg-violet-500 hover:bg-purple-500 hover:bg-fuchsia-500 hover:bg-pink-500 hover:bg-rose-500
  border-red-500 border-orange-500 border-amber-500 border-yellow-500 border-lime-500 border-green-500 border-teal-500 border-cyan-500 border-sky-500 border-blue-500 border-indigo-500 border-violet-500 border-purple-500 border-fuchsia-500 border-pink-500 border-rose-500
  !bg-red-500 !bg-orange-500 !bg-amber-500 !bg-yellow-500 !bg-lime-500 !bg-green-500 !bg-teal-500 !bg-cyan-500 !bg-sky-500 !bg-blue-500 !bg-indigo-500 !bg-violet-500 !bg-purple-500 !bg-fuchsia-500 !bg-pink-500 !bg-rose-500
  !text-red-500 !text-orange-500 !text-amber-500 !text-yellow-500 !text-lime-500 !text-green-500 !text-teal-500 !text-cyan-500 !text-sky-500 !text-blue-500 !text-indigo-500 !text-violet-500 !text-purple-500 !text-fuchsia-500 !text-pink-500 !text-rose-500
  hover:text-red-500 hover:text-orange-500 hover:text-amber-500 hover:text-yellow-500 hover:text-lime-500 hover:text-green-500 hover:text-teal-500 hover:text-cyan-500 hover:text-sky-500 hover:text-blue-500 hover:text-indigo-500 hover:text-violet-500 hover:text-purple-500 hover:text-fuchsia-500 hover:text-pink-500 hover:text-rose-500
  group-hover:text-red-500 group-hover:text-orange-500 group-hover:text-amber-500 group-hover:text-yellow-500 group-hover:text-lime-500 group-hover:text-green-500 group-hover:text-teal-500 group-hover:text-cyan-500 group-hover:text-sky-500 group-hover:text-blue-500 group-hover:text-indigo-500 group-hover:text-violet-500 group-hover:text-purple-500 group-hover:text-fuchsia-500 group-hover:text-pink-500 group-hover:text-rose-500
  hover:!bg-red-500 hover:!bg-orange-500 hover:!bg-amber-500 hover:!bg-yellow-500 hover:!bg-lime-500 hover:!bg-green-500 hover:!bg-teal-500 hover:!bg-cyan-500 hover:!bg-sky-500 hover:!bg-blue-500 hover:!bg-indigo-500 hover:!bg-violet-500 hover:!bg-purple-500 hover:!bg-fuchsia-500 hover:!bg-pink-500 hover:!bg-rose-500
  !border-red-500 !border-orange-500 !border-amber-500 !border-yellow-500 !border-lime-500 !border-green-500 !border-teal-500 !border-cyan-500 !border-sky-500 !border-blue-500 !border-indigo-500 !border-violet-500 !border-purple-500 !border-fuchsia-500 !border-pink-500 !border-rose-500
    hover:!border-red-500 hover:!border-orange-500 hover:!border-amber-500 hover:!border-yellow-500 hover:!border-lime-500 hover:!border-green-500 hover:!border-teal-500 hover:!border-cyan-500 hover:!border-sky-500 hover:!border-blue-500 hover:!border-indigo-500 hover:!border-violet-500 hover:!border-purple-500 hover:!border-fuchsia-500 hover:!border-pink-500 hover:!border-rose-500
  hover:border-red-500 hover:border-orange-500 hover:border-amber-500 hover:border-yellow-500 hover:border-lime-500 hover:border-green-500 hover:border-teal-500 hover:border-cyan-500 hover:border-sky-500 hover:border-blue-500 hover:border-indigo-500 hover:border-violet-500 hover:border-purple-500 hover:border-fuchsia-500 hover:border-pink-500 hover:border-rose-500
    dark:hover:border-red-500 dark:hover:border-orange-500 dark:hover:border-amber-500 dark:hover:border-yellow-500 dark:hover:border-lime-500 dark:hover:border-green-500 dark:hover:border-teal-500 dark:hover:border-cyan-500 dark:hover:border-sky-500 dark:hover:border-blue-500 dark:hover:border-indigo-500 dark:hover:border-violet-500 dark:hover:border-purple-500 dark:hover:border-fuchsia-500 dark:hover:border-pink-500 dark:hover:border-rose-500
    shadow-red-500 shadow-orange-500 shadow-amber-500 shadow-yellow-500 shadow-lime-500 shadow-green-500 shadow-teal-500 shadow-cyan-500 shadow-sky-500 shadow-blue-500 shadow-indigo-500 shadow-violet-500 shadow-purple-500 shadow-fuchsia-500 shadow-pink-500 shadow-rose-500
    dark:shadow-red-500 dark:shadow-orange-500 dark:shadow-amber-500 dark:shadow-yellow-500 dark:shadow-lime-500 dark:shadow-green-500 dark:shadow-teal-500 dark:shadow-cyan-500 dark:shadow-sky-500 dark:shadow-blue-500 dark:shadow-indigo-500 dark:shadow-violet-500 dark:shadow-purple-500 dark:shadow-fuchsia-500 dark:shadow-pink-500 dark:shadow-rose-500;
}
